.lead{
   font-weight: 400;
}

.clients{
.swiper-container, .swiper-conta {
   width: 100%; 
   overflow: hidden;
}

.swiper-slide {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 200px;
   padding: 15px;
   height: auto;
}
}

img {
   max-width: 100%;
   height: auto;
}

.btn-outline-primary:hover{
      color: $white !important;
}

.btn-outline-white:hover{
      color: $black !important;
}

.btn-primary{
      color: $white !important;
}

ul.list-unstyled-2{
   padding-left: 28px !important;
   list-style: none;
   margin: 0;
   padding: 0;
}

ul.list-unstyled-2 > li{
   font-size: $font-size-base;
   padding-left: 0;
   text-indent: -0.8rem;
}

ul.list-unstyled-2 > li:last-of-type{
   margin-bottom: 0;
}

.rounded-6{
   border-radius: 3.5rem;
}

@media (max-width:1400px){
   .rounded-7{
      border-radius: 3rem !important;
   }
}

@media (min-width:1025px){
   .rounded-7{
      border-radius: 9rem;
   }   
}

.width-3x {
   width: 2rem !important;
 }

.width-7x {
   width: 4rem !important;
 }

.btn-green{
   background-color: $green;
   color: $white;
}

.opacity-0-7{
   opacity: .7;
}

.bg-image.absolute {
   position: relative;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 1;
}

/* Damit der Text und die anderen Inhalte über dem Bild angezeigt werden */
.col-12 > *:not(.bg-image) {
   position: relative;
   z-index: 2;
}

.cursor-pointer{
   cursor: pointer;
}

.rounded-top-6{
   border-top-left-radius: 3.5rem;
   border-top-right-radius: 3.5rem;
}

.rounded-top-7{
   border-top-left-radius: 9rem;
   border-top-right-radius: 9rem;
}

.rounded-bottom-6{
   border-bottom-left-radius: 3.5rem;
   border-bottom-right-radius: 3.5rem;
}

.rounded-bottom-7{
   border-bottom-left-radius: 9rem;
   border-bottom-right-radius: 9rem;
}

.nav-link{
   color: $black;
}

.nav-link:hover{
   color: $primary;
}

.card.spezial{
   width: 50%;
   left: 290px;
   position: relative;
   margin-top: -150px;
}

@media (max-width: 440px) {
   .card.spezial{
      width: 70%;
      margin-top: -105px;
      position: absolute;
      left: 80px;
         }
}


@media (max-width: 440px) {
.btn{
   width: 100% !important;
}
.btn.spezial{
   width: 100% !important;
   display: inline-block;
}
.navbar-brand{
   width: 10rem;
}
.rounded-6{
   border-radius: 2rem;
}

.rounded-top-6{
   border-top-left-radius: 2rem;
   border-top-right-radius: 2rem;
}

.rounded-top-7{
   border-top-left-radius: 3rem;
   border-top-right-radius: 3rem;
}
}

.navbar-toggler{
   position: fixed;
   bottom: 0;
   right: 0;
   z-index: 999999999;
   width: 80px;
   height: 80px;
   background-color: $green;
   border-top-right-radius: 0 !important;
   border-top-left-radius: 20px;
   border-bottom-right-radius: 0;
   border-bottom-left-radius: 0;
   }
   .navbar-toggler .navbar-toggler-icon{
      width: 35px;
      height: 35px;
      color: $white;
   }

.si.si-border{
   border-color:  $black!important;
}

header:not(.scroll-up):not(.is-sticky):not(.is-fixed) {
   .navbar-light.navbar-link-white {
       .navbar-nav {
           .nav-link {
               color: $navbar-light-color;

               &:hover,
               &:focus {
                   color: $navbar-light-hover-color;
               }

               &.active {
                   color: $navbar-light-active-color;
               }
           }
       }
   }
}

header.header-transparent.sticky-fixed .navbar .navbar-fixed-bg{
   box-shadow: none;
}

@media (min-width:1400px){
   nav .container{
      max-width: 1660px;
   }
   nav.subpage .container{
      max-width: 1400px;
   }
}

footer{
   .footer-logos img{
      width: 50%;
   }
}

@media (max-width: 414px) {
   footer{
      .footer-logos img{
         width: 40%;
      }
   }
}

.text-cols {
   .small-numbers{
   padding: 12px 11px;
   background-color: $green;
   display: inline-block;
   border-radius: 14px;
   min-width: 40px;
   max-width: 40px;
   align-items: center;
   justify-content: center;
   text-align: center;
}
}


.small-numbers p{
   margin-bottom: 0;
   color: #ffffff;
   font-weight: 700;
   font-size: 0.9rem !important;
}

@media (max-width: 440px) {
   .small-numbers{
      padding: 19px 11px;
      margin-bottom: 0px;
      min-width: 45px;
      max-width: 45px;
   }
   
   .small-numbers p{
      font-size: 0.85rem !important;
   }
}

.accordion.accordion-custom .accordion-button::after{
   content: "\ebc0" !important;
   font-size: 1.5rem;
}

@media (max-width: 440px) {
   h4{
      font-size: 1.2rem;
   }
}

@media (max-width: 992px) {
   .toTop{
   left: 15px;
   }
   .navbar{
      padding: 20px 0 !important;
   }
}

h3{
   line-height: 1.3;
}

h6{
   font-size: 1.09rem;
}

.lead{
   font-size: calc(1.2rem + .075vw);
}

.accordion-collapse,.accordion-button:not(.collapsed){
   background-color:$white !important;
   color: var(--#{$prefix}body-color);
}

@media (min-width: 440px) {
.is-fixed .navbar{
   padding: 20px 0;

}
}


.sticky-fixed{
   background-color: #ffffff !important;
}

.sticky-fixed.is-fixed{
   background-color: transparent !important;
}

@media (min-width: 768px) {
header.subpage .navbar-brand{
   width: 13rem;
}
}

@media (max-width: 397px) {
   .navbar-brand{
      width: 8rem;
   }
}

@media (max-width: 374px) {
   .navbar-brand{
      width: 5rem;
   }
}

@media (max-width: 440px) {
header.header-transparent.sticky-fixed.is-fixed .navbar-light.navbar-link-white .navbar-brand>.navbar-brand-transparent {
   opacity: 1;
   visibility: visible;
}
}

/*.tab-content{
   margin-top: -30px;
}*/

[data-bs-theme=dark] {
   .sticky-fixed{
      background-color: #0f1321 !important;
   }
   .sticky-fixed.is-fixed{
      background-color: transparent !important;
   }
   .text-dark{
      color: #ffffff !important;
   }
   footer .nav-link{
      color: #ffffff !important;
   }
   footer .nav-link:hover{
      color: $orange !important;
   }
   header .navbar .nav-link{
      color: #ffffff !important;
   }
   header .navbar .nav-link:hover{
      color: $orange !important;
   }
   .bg-white{
      background-color: #272d43 !important
   }
   .bg-white-spezial{
      background-color: #ffffff !important
   }
   
   #klimaktiv-null{
   .cls-1{
      fill: #ffffff !important;
   }
   .cls-2{
      stroke: #ffffff !important;
   }
   .cls-3{
      fill: #ffffff !important;
   }
   }
   .accordion-button:not(.collapsed), .accordion-collapse{
      background-color: #0f1321 !important;
   }
   .accordion-collapse {
      background-color: #0f1321 !important
   }
   .nav-link{
      color: #ffffff;
   }
   .nav-link:hover{
      color: $orange;
   }
}

.dropdown-item:hover{
   background-color: transparent;
   color: $orange;
}

header .navbar .nav-link:hover{
   color: $orange !important;
}

.indicator-vertical{
   border: 1px solid $primary !important;
}

.shadow-xl {
   box-shadow: 0 2rem 4rem rgba(15,19,33,.0875)!important;
}

.linkitems a:hover{
   p{
      color: #1a1e30 !important;
   }
}

.linkitems{
   p{
      color: #1a1e30 !important;
      margin-bottom: 0 !important;
   }
}

.border-xl-end {
   @include media-breakpoint-up(xl) {
     border-right: 1px solid $border-color; // Standard-Borderfarbe verwenden
   }
 }

 li.checker-li {
   list-style-type: none;
   padding-left: 40px;
   font-size: 1.05rem;
   background: url("/themebs5/images/icons/check_sm.svg") no-repeat left center;
   margin-block-start: .5em;
}

li.checker-li-small {
   list-style-type: none;
   padding-left: 20px;
   //font-size: 20px;
   //line-height: 40px;
   background: url("/themebs5/images/icons/check_xs.svg") no-repeat left 6px;
   margin-block-start: .5em;
}

li.icon-check:not(:last-of-type){
   margin-bottom: 1rem;
}

.icon-check::before {
   content: "\ea41";  /* Angenommener Unicode-Wert für das gewünschte Icon */
   font-family: boxicons !important;
   display: inline-block;
   vertical-align: middle;
   margin-right: 8px;  /* Abstand zum Text */
}

.accordion{
   ul {
      padding-left: 0px !important;
      list-style: none;
      margin: 0;
      padding: 0;
   }
   ul > li::before{
      content: "\ea41" !important;  /* Angenommener Unicode-Wert für das gewünschte Icon */
      font-family: boxicons !important;
      display: inline-block !important;
      vertical-align: middle !important;
      margin-right: 8px !important;  /* Abstand zum Text */
   }
   ul > li:last-of-type{
         margin-bottom: 1rem !important;
   }
}



.navbar .megamenu{ padding: 1rem; }

/* ============ desktop view ============ */
@media all and (min-width: 992px) {

  .navbar .has-megamenu{position:static!important;}
  .navbar .megamenu{left:0; right:0; width:100%; margin-top:0;  }

}	
/* ============ desktop view .end// ============ */

/* ============ mobile view ============ */
@media(max-width: 991px){
  .navbar.fixed-top .navbar-collapse, .navbar.sticky-top .navbar-collapse{
    overflow-y: auto;
      max-height: 90vh;
      margin-top:10px;
  }
}
/* ============ mobile view .end// ============ */

.nav-link:focus-visible{
   outline: none !important;
   box-shadow: none !important;
}

