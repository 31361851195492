#_1-klima-bilanz-disabled,
#_2-klima-strategie-disabled,
#_3-klima-aktion-disabled,
#_4-klima-kommunikation-disabled {
  cursor: pointer;
  transition: fill 0.2s;
  fill: #f2f2f2;
}

#_1-klima-bilanz-disabled:hover,
#_2-klima-strategie-disabled:hover,
#_3-klima-aktion-disabled:hover,
#_4-klima-kommunikation-disabled:hover {
  fill: $blue
}

#_1-klima-bilanz-aktiv {
  display: block;
  cursor: pointer;
  transition: opacity 0.2s;
}

#_2-klima-strategie-aktiv,
#_3-klima-aktion-aktiv,
#_4-klima-kommunikation-aktiv {
  display: none;
  transition: opacity 0.2s;
  cursor: pointer;
}

#_1-klima-bilanz-text{
  opacity: 1;
  cursor: pointer;
  transition: opacity 0.2s;
  display: block;
}

#_2-klima-strategie-text,
#_3-klima-aktion-text,
#_4-klima-kommunikation-text {
  opacity: 0.3;
  cursor: pointer;
  width: auto;
  height: auto;
  transition: opacity 0.2s;
  display: block;
}

.cls-1 {
   isolation: isolate;
   }
   .cls-2 {
   fill: none;
   }
   .cls-3,
   .cls-4,
   .cls-5,
   .cls-6,
   .cls-7 {
   stroke-width: 0px;
   }
   .cls-4 {
   fill: $blue;
   }
   .cls-5 {
   fill: $white;
   }
   .cls-6 {
   fill: $white;
   }
   .cls-7 {
      fill: $primary;
   }

   /* MOBILE */

   #null_mobile{
      fill: $primary;
   }

   #_1-klima-bilanz-disabled_mobile,
   #_2-klima-strategie-disabled_mobile,
   #_3-klima-aktion-disabled_mobile,
   #_4-klima-kommunikation-disabled_mobile {
   cursor: pointer;
   transition: fill 0.2s;
   fill: #f2f2f2;
   }

   #_1-klima-bilanz-aktiv_mobile_number,
   #_2-klima-strategie-aktiv_mobile_number,
   #_3-klima-aktion-aktiv_mobile_number,
   #_4-klima-kommunikation-aktiv_mobile_number,
   #_1-klima-bilanz-aktiv_mobile_text,
   #_2-klima-strategie-aktiv_mobile_text,
   #_3-klima-aktion-aktiv_mobile_text,
   #_4-klima-kommunikation-aktiv_mobile_text {
   cursor: pointer;
   }

    #_1-klima-bilanz-aktiv_mobile,
    #_2-klima-strategie-aktiv_mobile,
    #_3-klima-aktion-aktiv_mobile,
    #_4-klima-kommunikation-aktiv_mobile {
      fill: $blue;
    }

    #null{
      fill: $primary;
    }

    .klimaktiv-null-section-xl{
      .small-numbers{
        padding: 12px 11px;
        background-color: $blue !important;
        display: flex;
        border-radius: 14px;
        min-width: 40px;
        max-width: 40px;
        min-height: 47px;
        max-height: 47px;
        align-items: center;
        justify-content: center;
        text-align: center;
     }
     
     .card-title{
      display: flex;
      align-items: center;
     }
     
     .small-numbers p{
        margin-bottom: 0;
        color: #ffffff;
        font-weight: 700;
        font-size: 0.9rem !important;
     }
    }

    #klimajourney-desktop{
      padding:  7rem 0;
     }

     .klimajourney-card{
      width: 380px !important;
     }

    @media (max-width: 1400px) {
     #klimajourney-desktop{
      padding: 11rem 0;
     }
     .klimajourney-card{
      width: 300px !important;
     }
    }

    .nav-inline{
      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
      overflow-x: auto;
      scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer and Edge */
    ::-webkit-scrollbar {
      display: none; /* Webkit-based browsers */
    }
    }
    .indicator,.indicator-vertical{
      transition: all .3s;
    }


.klimaktiv-null-section-sm{
  .small-numbers{
    background-color: $blue !important;
    display: inline-block;
    border-radius: 14px;
    padding: 19px 11px;
    margin-bottom: 0;
    min-width: 45px;
    max-width: 45px;
    align-items: center;
    justify-content: center;
    text-align: center;
 }
}