.hero{
    .null{
        width: 30%;
        margin-top: -390px;
        position: absolute !important;
        right: -250px;
    }
    
    @media (max-width: 414px) {
        .null{
            width: 60%;
            margin-top: -120px;
            position: absolute !important;
            right: -30px;
            mix-blend-mode: multiply;
        }
    }

    .rounded-6{
        border-radius: 4.5rem;
     }
}

@media (min-width:992px){
    .navbar-expand-lg .navbar-nav .dropdown-menu{
        margin-top: 0rem !important;
    }
    header.is-fixed .navbar .dropdown:hover > .dropdown-menu{
        top: 68px !important;
    }
}