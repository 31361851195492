



@font-face {
    font-family: 'FontAwesome';
    src: url("../../../fonts/font-awesome/fontawesome-webfont.eot?v=4.7.0");
    src: url("../../../fonts/font-awesome/fontawesome-webfont.eot?#iefix&v=4.7.0") format('embedded-opentype'),url("../../../fonts/font-awesome/fontawesome-webfont.woff2?v=4.7.0") format('woff2'),url("../../../fonts/font-awesome/fontawesome-webfont.woff?v=4.7.0") format('woff'),url("../../../fonts/font-awesome/fontawesome-webfont.ttf?v=4.7.0") format('truetype'),url("../../../fonts/font-awesome/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format('svg');
    font-weight: normal;
    font-style: normal;
}
/*themify icons*/
@font-face {
    font-family: 'themify';
    src: url("../../../fonts/themify-icons/themify.eot");
    src: url("../../../fonts/themify-icons/themify.eot?#iefix") format('embedded-opentype'),
    url("../../../fonts/themify-icons/themify.woff") format('woff'),
    url("../../../fonts/themify-icons/themify.ttf") format('truetype'),
    url("../../../fonts/themify-icons/themify.svg") format('svg');
    font-weight: normal;
    font-style: normal;
}
/**et line icons**/
@font-face {
    font-family: 'et-line';
    src:url('../../../fonts/et-line-font/et-line.eot');
    src:url('../../../fonts/et-line-font/et-line.eot?#iefix') format('embedded-opentype'),
    url('../../../fonts/et-line-font/et-line.woff') format('woff'),
    url('../../../fonts/et-line-font/et-line.ttf') format('truetype'),
    url('../../../fonts/et-line-font/et-line.svg#et-line') format('svg');
    font-weight: normal;
    font-style: normal;
}
