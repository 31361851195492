.null{
   width: 20%;
   margin-top: -270px;
   position: absolute;
   right: 0;
}

.null-big{
   margin-top: -270px;
   position: absolute;
   right: 0;
}

.ka-shape{
   width: 50%;
   bottom: -350px;
   position: absolute!important;
   mix-blend-mode: multiply;
   &.left{
      left: -430px;
   }
   &.right{
      right: 430px;
   }
}

.ka-shape-small{
   width: 25%;
   bottom: -125px;
   position: absolute!important;
   mix-blend-mode: multiply;
   &.right{
      right: -130px;
   }
   &.left{
      left: -130px;
   }
}

.null-mask svg{
   border-radius: 25%;
}

.orange{
   fill: $orange;
}

.green{
   fill: $green
}

@media (max-width: 414px) {
   .null{
      width: 60%;
      margin-top: -515px;
      position: absolute;
      right: -90px;
   }
}
